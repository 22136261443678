<template>
  <div class="text-black mb-3">
    <div v-if="!showDetail">
      <div class="mb-0 text-blue-800 text-base flex justify-end">
        <!-- <template v-if="isHasEHCSync">
          <div class="text-black fs-14 mr-3 cursor-pointer fw-600" :class="{
      'pointer-events-none': isSyncing,
    }" @click="handleSyncEHC()">
            <span class="align-middle mr-2">
              <i v-if="isSyncing" class="el-icon-loading text-lg" />
              <i v-else class="el-icon-refresh text-lg" />
            </span>
            <span class="align-middle">Đồng bộ dữ liệu EHC</span>
          </div>
        </template> -->
        <div class="text-black fs-14 mr-3 cursor-pointer fw-600" @click="onShowModalUpload(true)">
          <svg class="mr-1" width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.039 5.13579L8.1 3.06528V11.6984C8.1 11.9372 8.19482 12.1662 8.3636 12.335C8.53239 12.5038 8.76131 12.5987 9 12.5987C9.23869 12.5987 9.46761 12.5038 9.6364 12.335C9.80518 12.1662 9.9 11.9372 9.9 11.6984V3.06528L11.961 5.13579C12.0447 5.22017 12.1442 5.28714 12.2539 5.33284C12.3636 5.37855 12.4812 5.40208 12.6 5.40208C12.7188 5.40208 12.8364 5.37855 12.9461 5.33284C13.0558 5.28714 13.1553 5.22017 13.239 5.13579C13.3234 5.05211 13.3903 4.95254 13.436 4.84284C13.4817 4.73314 13.5052 4.61547 13.5052 4.49663C13.5052 4.37779 13.4817 4.26013 13.436 4.15043C13.3903 4.04073 13.3234 3.94116 13.239 3.85747L9.639 0.256576C9.55341 0.174619 9.45248 0.110375 9.342 0.067529C9.12288 -0.0225097 8.87712 -0.0225097 8.658 0.067529C8.54752 0.110375 8.44659 0.174619 8.361 0.256576L4.761 3.85747C4.67709 3.94141 4.61052 4.04106 4.56511 4.15072C4.51969 4.26039 4.49632 4.37793 4.49632 4.49663C4.49632 4.61534 4.51969 4.73288 4.56511 4.84254C4.61052 4.95221 4.67709 5.05186 4.761 5.13579C4.84491 5.21973 4.94454 5.28631 5.05418 5.33173C5.16382 5.37716 5.28133 5.40054 5.4 5.40054C5.51867 5.40054 5.63618 5.37716 5.74582 5.33173C5.85546 5.28631 5.95509 5.21973 6.039 5.13579ZM17.1 8.99776C16.8613 8.99776 16.6324 9.0926 16.4636 9.26142C16.2948 9.43025 16.2 9.65922 16.2 9.89798V15.2993C16.2 15.5381 16.1052 15.7671 15.9364 15.9359C15.7676 16.1047 15.5387 16.1996 15.3 16.1996H2.7C2.4613 16.1996 2.23239 16.1047 2.0636 15.9359C1.89482 15.7671 1.8 15.5381 1.8 15.2993V9.89798C1.8 9.65922 1.70518 9.43025 1.5364 9.26142C1.36761 9.0926 1.13869 8.99776 0.9 8.99776C0.661305 8.99776 0.432387 9.0926 0.263604 9.26142C0.0948211 9.43025 0 9.65922 0 9.89798V15.2993C0 16.0156 0.284464 16.7025 0.790812 17.209C1.29716 17.7155 1.98392 18 2.7 18H15.3C16.0161 18 16.7028 17.7155 17.2092 17.209C17.7155 16.7025 18 16.0156 18 15.2993V9.89798C18 9.65922 17.9052 9.43025 17.7364 9.26142C17.5676 9.0926 17.3387 8.99776 17.1 8.99776Z"
              fill="#03267A"
            />
          </svg>
          Upload Phiếu
        </div>
        <div class="flex items-center">
          <span @click="ModalCreate(true)" class="fs-14 fw-700 text-grey-900 cursor-pointer">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path
                d="M8.99984 0.666748C7.35166 0.666748 5.7405 1.15549 4.37009 2.07117C2.99968 2.98685 1.93158 4.28834 1.30084 5.81105C0.670115 7.33377 0.505087 9.00933 0.82663 10.6258C1.14817 12.2423 1.94185 13.7272 3.10728 14.8926C4.27272 16.0581 5.75758 16.8517 7.37409 17.1733C8.9906 17.4948 10.6662 17.3298 12.1889 16.6991C13.7116 16.0683 15.0131 15.0002 15.9288 13.6298C16.8444 12.2594 17.3332 10.6483 17.3332 9.00008C17.3332 7.90573 17.1176 6.8221 16.6988 5.81105C16.28 4.80001 15.6662 3.88135 14.8924 3.10752C14.1186 2.3337 13.1999 1.71987 12.1889 1.30109C11.1778 0.882296 10.0942 0.666748 8.99984 0.666748ZM8.99984 15.6667C7.6813 15.6667 6.39237 15.2758 5.29604 14.5432C4.19971 13.8107 3.34523 12.7695 2.84064 11.5513C2.33606 10.3331 2.20404 8.99269 2.46127 7.69948C2.71851 6.40627 3.35345 5.21839 4.2858 4.28604C5.21815 3.35369 6.40603 2.71875 7.69924 2.46151C8.99245 2.20428 10.3329 2.3363 11.5511 2.84088C12.7692 3.34547 13.8104 4.19995 14.543 5.29628C15.2755 6.39261 15.6665 7.68154 15.6665 9.00008C15.6665 10.7682 14.9641 12.4639 13.7139 13.7141C12.4636 14.9644 10.768 15.6667 8.99984 15.6667ZM12.3332 8.16675H9.83317V5.66675C9.83317 5.44573 9.74538 5.23377 9.5891 5.07749C9.43282 4.92121 9.22085 4.83342 8.99984 4.83342C8.77883 4.83342 8.56687 4.92121 8.41059 5.07749C8.25431 5.23377 8.16651 5.44573 8.16651 5.66675V8.16675H5.66651C5.44549 8.16675 5.23353 8.25455 5.07725 8.41083C4.92097 8.56711 4.83317 8.77907 4.83317 9.00008C4.83317 9.2211 4.92097 9.43306 5.07725 9.58934C5.23353 9.74562 5.44549 9.83342 5.66651 9.83342H8.16651V12.3334C8.16651 12.5544 8.25431 12.7664 8.41059 12.9227C8.56687 13.079 8.77883 13.1667 8.99984 13.1667C9.22085 13.1667 9.43282 13.079 9.5891 12.9227C9.74538 12.7664 9.83317 12.5544 9.83317 12.3334V9.83342H12.3332C12.5542 9.83342 12.7662 9.74562 12.9224 9.58934C13.0787 9.43306 13.1665 9.2211 13.1665 9.00008C13.1665 8.77907 13.0787 8.56711 12.9224 8.41083C12.7662 8.25455 12.5542 8.16675 12.3332 8.16675Z"
                fill="#03267A"
              />
            </svg>
            Thêm Phiếu mới
          </span>
        </div>
      </div>

      <div v-if="treatments" v-loading="loading">
        <el-table @row-click="goToViewDetail" class="cursor-pointer" :data="treatmentsPaging.data" :tree-props="{ children: 'emr_treatments' }" row-key="date" :default-sort="{ prop: 'date', order: 'descending' }" style="width: 100%" row-class-name="text-black text-sm" header-cell-class-name="text-black text-base font-semibold" default-expand-all>
          <el-table-column label="Phiếu ngày">
            <template slot-scope="scope">
              <span v-if="scope.row.id">
                <i class="el-icon-time mr-1"></i>
                <span>{{ formatTime(scope.row) }}</span>
              </span>
              <span v-else>
                <i class="el-icon-date mr-1"></i>
                <span>{{ formatDate(scope.row) }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Người tạo phiếu" :formatter="getUserName"></el-table-column>
          <el-table-column prop="treatment_workspace" label="Khoa điều trị"></el-table-column>
          <el-table-column label class-name="text-right">
            <template slot-scope="scope">
              <div v-if="!scope.row.id" class="inline-block">
                <el-button size="mini" icon="el-icon-printer">{{ $t("multidisciplinary_board.lbl_print") }}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-content-end mt-3">
          <el-pagination v-if="treatments && treatmentsPaging.last_page > 1" background layout="prev, pager, next" :total="treatmentsPaging.total" :page-size="parseInt(treatmentsPaging.per_page)" @current-change="handleCurrentPage"></el-pagination>
        </div>
      </div>
      <ModalEMRTreatUpload ref="ModalEMRTreatUpload" @onSubmit="postERMTreatment($event)" />
    </div>
    <div v-else>
      <Detail :treatmentId="treatmentId" @refresh="getERMTreatmentsByDate" :formData="form" :isLoading="loading" @onSignSuccess="handleSignSuccess" />
    </div>
    <!-- view -->
    <div class="modal fade" id="modalViewUploadTreatment" tabindex="-1" role="dialog" aria-labelledby="modalViewUploadTreatmentLabel" aria-hidden="true">
      <div class="modal-dialog modal-sx" role="document">
        <div class="modal-content" v-loading="loading">
          <div class="d-flex justify-content-between p-3">
            <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
              <span class="uppercase ml-5">Chi tiết phiếu</span>
            </div>
            <div v-if="modeUpdate === null" @click="modeUpdateOn()" class="mt-2.5 cursor-pointer mr-2">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.75 8.98568C15.5511 8.98568 15.3603 9.06 15.2197 9.19228C15.079 9.32457 15 9.50399 15 9.69107V13.9234C15 14.1105 14.921 14.2899 14.7803 14.4222C14.6397 14.5545 14.4489 14.6288 14.25 14.6288H3.75C3.55109 14.6288 3.36032 14.5545 3.21967 14.4222C3.07902 14.2899 3 14.1105 3 13.9234V4.04798C3 3.8609 3.07902 3.68148 3.21967 3.5492C3.36032 3.41691 3.55109 3.3426 3.75 3.3426H8.25C8.44891 3.3426 8.63968 3.26828 8.78033 3.13599C8.92098 3.00371 9 2.82429 9 2.63721C9 2.45013 8.92098 2.27071 8.78033 2.13843C8.63968 2.00614 8.44891 1.93182 8.25 1.93182H3.75C3.15326 1.93182 2.58097 2.15478 2.15901 2.55163C1.73705 2.94849 1.5 3.48674 1.5 4.04798V13.9234C1.5 14.4846 1.73705 15.0229 2.15901 15.4197C2.58097 15.8166 3.15326 16.0395 3.75 16.0395H14.25C14.8467 16.0395 15.419 15.8166 15.841 15.4197C16.2629 15.0229 16.5 14.4846 16.5 13.9234V9.69107C16.5 9.50399 16.421 9.32457 16.2803 9.19228C16.1397 9.06 15.9489 8.98568 15.75 8.98568ZM4.5 9.52177V12.5126C4.5 12.6997 4.57902 12.8791 4.71967 13.0114C4.86032 13.1437 5.05109 13.218 5.25 13.218H8.43C8.5287 13.2185 8.62655 13.2007 8.71793 13.1656C8.80931 13.1305 8.89242 13.0788 8.9625 13.0134L14.1525 8.12511L16.2825 6.16414C16.3528 6.09856 16.4086 6.02055 16.4467 5.93459C16.4847 5.84863 16.5043 5.75643 16.5043 5.66331C16.5043 5.5702 16.4847 5.478 16.4467 5.39204C16.4086 5.30608 16.3528 5.22807 16.2825 5.16249L13.1025 2.13639C13.0328 2.07027 12.9498 2.01779 12.8584 1.98198C12.767 1.94617 12.669 1.92773 12.57 1.92773C12.471 1.92773 12.373 1.94617 12.2816 1.98198C12.1902 2.01779 12.1072 2.07027 12.0375 2.13639L9.9225 4.13263L4.7175 9.02095C4.64799 9.08686 4.59299 9.16503 4.55567 9.25097C4.51835 9.33692 4.49943 9.42894 4.5 9.52177ZM12.57 3.6318L14.6925 5.62805L13.6275 6.62969L11.505 4.63345L12.57 3.6318ZM6 9.81098L10.4475 5.62805L12.57 7.62429L8.1225 11.8072H6V9.81098Z"
                  fill="black"
                />
              </svg>
            </div>
            <button type="button" class="close bg-white border-0" id="closeModal" @click="closeModal(false)">
              <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12 mb-3">
                    <div class="form-group">
                      <label :class="!modeUpdate ? 'fs-16 fw-500 txt-grey-900' : 'fs-16 fw-500 txt-grey-900 required'">Tên Phiếu</label>
                      <input v-if="modeUpdate" type="text" class="form-control mt-2 txt-grey-900 fs-16 fw-500" placeholder="Nhập tên phiếu" v-model="form.name" />
                      <div v-else class="fw-500 txt-grey-900 mt-2">
                        {{ form.name }}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 mb-3">
                    <div class="form-group">
                      <label :class="!modeUpdate ? 'fs-16 fw-500 txt-grey-900' : 'fs-16 fw-500 txt-grey-900 required'">Phiếu ngày</label>
                      <!-- <el-date-picker
                        type="datetime"
                        placeholder="Chọn ngày giờ"
                        v-model="form.date"
                      ></el-date-picker>-->
                      <input v-if="modeUpdate" type="datetime-local" class="form-control" v-model="form.date" />
                      <div v-else class="fw-500 txt-grey-900 mt-2">
                        {{ formatDateTime(form.date) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 mb-3">
                    <div class="form-group">
                      <label class="fs-16 fw-500 txt-grey-900">Người tạo phiếu</label>
                      <el-select v-if="modeUpdate" v-model="form.user_id" allow-create filterable remote reserve-keyword placeholder="Tìm kiếm tên bác sĩ" class="w-100" :remote-method="getDoctor" :loading="loadingDoctor">
                        <el-option v-for="item in doctors" :key="item.id + 'dtr'" :label="item.name" :value="item.user_id"></el-option>
                      </el-select>
                      <div v-else class="fs-16 fw-500 txt-grey-900 mt-2">
                        {{ form.user ? form.user.name : form.user_name }}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 mb-3">
                    <div class="form-group">
                      <label class="fs-16 fw-500 txt-grey-900">Khoa điều trị</label>
                      <input v-if="modeUpdate" type="text" class="form-control mt-2 txt-grey-900 fs-16 fw-500" placeholder="Khoa điều trị" v-model="form.treatment_workspace" />
                      <div v-else class="fw-500 txt-grey-900 mt-2">
                        {{ form.treatment_workspace }}
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 row">
                    <div class="col-md-9">
                      <div v-if="modeUpdate">
                        <p class="mt-3 fs-14" v-if="!file">
                          {{ getFilename(form.pdf_url) }}
                        </p>
                        <p v-else>{{ getFilename(file.name) }}</p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div v-if="modeUpdate">
                        <div @click="onShowPickFileTreatment" class="mt-3 ml-4 fs-16 text-blue-900 underline cursor-pointer hover:text-blue-500">Thay đổi</div>
                        <input id="inputFileTreatment" class="d-none" name="inputFileTreatment" type="file" accept="jpeg, .png, .jpg, .gif, .svg, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .ppt, .pptx, .heic" @change="onSelectFileTreatment" :key="fileInputKey" />
                      </div>
                    </div>
                  </div>
                  <div class="text-center" v-if="!modeUpdate">
                    <a href="javascript:;" class="txt-pri fs-14 text-decoration-underline" @click="openFile(form && form.pdf_url)">Xem phiếu</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn bg-white text-pri" type="button" v-if="modeUpdate" @click="closeUpdate">Hủy</button>
            <button v-if="modeUpdate" @click="onSubmit" type="button" class="btn bg-pri text-white">Lưu</button>
          </div>
        </div>
      </div>
    </div>
    <!-- view -->
    <modalCreateForm :type="'create'" @onCreate="createERMTreatment($event)" @onSuccess="getERMTreatmentsByDate()" />
    <PdfViewer ref="TreatmentFormPdfViewer" containerIDProps="ContainerTreatmentFormPDFViewerID" elementIDProps="TreatmentFormPDFViewerID" />
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex'
import SignatureRequest from '../../../api/request/SignatureRequest'
import appUtils from '../../../utils/appUtils'
import { ENVELOPE_DOCUMENT_TYPE } from '../../../utils/constants'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'
import Detail from './Detail'
import modalCreateForm from './ModalTreatment.vue'
import ModalEMRTreatUpload from './ModalUpload.vue'

export default {
  name: 'TreatmentList',
  components: { Detail, ModalEMRTreatUpload, modalCreateForm, PdfViewer },
  data () {
    const initPagination = {
      total: 0,
      count: 0,
      per_page: 5,
      current_page: 1,
      last_page: 1
    }
    return {
      loading: false,
      loadingDoctor: false,
      modeUpdate: null,
      treatmentsPaging: null,
      treatments: null,
      doctors: [],
      treatmentId: null,
      form: {
        medical_record_id: null,
        name: null,
        date: null,
        format: 2,
        pdf: null,
        pdf_url: '',
        user_id: ''
      },
      file: null,
      listStatus: [{ name: 'Mới' }, { name: 'Đang xử lý' }, { name: 'Đã ký' }, { name: 'Đã thay thế' }],
      showDetail: false,
      initPagination,
      pagination: initPagination,
      fileInputKey: 0,
      isSyncing: false
    }
  },
  computed: {
    ...mapState({
      person: state => state.person,
      medicalRecordMain: state => state.medicalRecordMain,
      emrData: state => state.emrData
    }),
    isHasEHCSync () {
      const settings = appUtils.isJSON(this.$globalClinic?.settings) ? JSON.parse(this.$globalClinic?.settings) : {}
      return !!settings?.ehc_config
    }
  },
  watch: {
    '$route.query.id_treatment' () {
      this.handleDetail()
    },
    '$route.query.treatment_date' () {
      this.handleDetail()
    },
    showDetail (val) {
      if (!val) this.getERMTreatmentsByDate()
    }
  },
  created () {
    this.$eventBus.$on('syncVimes', this.handleMount)
  },
  beforeDestroy () {
    this.$eventBus.$off('syncVimes', this.handleMount)
  },
  mounted () {
    this.handleMount()
    let self = this
    this.$nextTick(function () {
      window.$('#modalViewUploadTreatment').on('hidden.bs.modal', function (e) {
        self.getERMTreatmentByIDV2()
        self.getERMTreatmentsByDate()
        self.modeUpdate = null
        self.refresh()
      })
      window.$('#modalViewUploadTreatment').on('hide.bs.modal', function (e) {
        if (self.modeUpdate) {
          if (!confirm('Các thay đổi có thể chưa được lưu. Bạn có muốn lưu không?')) {
            return true
          } else self.onSubmit()
        }
        self.refresh()
      })
    })
  },
  methods: {
    async handleMount () {
      await this.handleDetail()
      if (!this.showDetail) await this.getERMTreatmentsByDate()
    },
    refresh () {
      const fileInput = document.getElementById('inputFileTreatment')

      this.file = null
      if (fileInput && fileInput.value) fileInput.value = ''
      this.fileInputKey++
    },
    modeUpdateOn () {
      this.modeUpdate = 1
      this.getDoctor()
    },
    openFile (url) {
      if (!url) return
      window.open(url)
    },
    async onSubmit () {
      this.form.pdf = this.file
      if (isNaN(this.form.user_id)) {
        this.form.user_name = this.form.user_id
        this.form.user_id = null
      } else {
        const doctor = this.doctors.find(item => (item.user_id = this.form.user_id))
        this.form.user_name = doctor?.name || ''
      }
      const formData = new FormData()
      for (var key in this.form) {
        formData.append(key, this.form[key] || '')
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .updateERMTreatment(this.treatmentId, formData)
        .then(r => {
          this.modeUpdate = null
          this.getERMTreatmentByIDV2()
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
          this.refresh()
        })
    },
    closeModal (show) {
      window.$('#modalViewUploadTreatment').modal(show ? 'show' : 'hide')
      this.refresh()
    },
    closeUpdate () {
      this.getERMTreatmentByIDV2()
      this.modeUpdate = null
      this.file = null
      this.refresh()
    },
    onSelectFileTreatment (event) {
      console.log(event.target.files[0])
      if (event.target.files[0]) {
        this.file = event.target.files[0]
      }
    },
    onShowPickFileTreatment () {
      window.$('input[id="inputFileTreatment"]').trigger('click')
    },
    formatDateTime (date) {
      return moment(date).format('HH:mm:ss DD/MM/YYYY')
    },
    getFilename (fullPath) {
      if (!_.isString(fullPath)) return
      return fullPath.split('/').pop()
    },
    handleDetail () {
      if (this.$route.query?.id_treatment || this.$route.query?.treatment_date) {
        this.showDetail = true
      } else {
        this.showDetail = false
      }
    },
    formatStatus (row) {
      return row.status ? this.listStatus[row.status].name : this.listStatus[0].name
    },
    formatName (row) {
      return row.name && row.name + ' ' + this.formatDMY(row)
    },
    formatFile (row) {
      return row.format === 1 ? 'Dữ liệu' : 'File'
    },
    formatDMY (row) {
      return row.date && window.moment(row.date).format('HH:mm:ss DD/MM/YYYY')
    },
    formatDate (row) {
      return row.date && appUtils.formatDate(row.date, true)
    },
    formatTime (row) {
      return row.date && appUtils.formatTime(row.date, true)
    },
    getUserName (row) {
      return (row.user && row.user.name) || row.user_name
    },
    async ehcSyncCoreTreatment (ids) {
      if (!ids.length || !this.isHasEHCSync) return

      try {
        const data = {
          treatment_id: ids,
          medical_record_id: Number(this.emrData?.id),
          medical_record_hv_id: Number(this.emrData?.ref_id)
        }
        await this.$rf.getRequest('EHCSyncRequest').ehcSyncCoreTreatmentMedicalRecord(data)
      } catch (error) {
        console.log(error)
      }
    },
    async goToViewDetail (row) {
      this.loading = true

      const url = await this.handleGetEnvelopeByDocumentID(row)
      if (url) {
        this.$refs.TreatmentFormPdfViewer.handleOpen(url, ENVELOPE_DOCUMENT_TYPE.TREATMENT_FORM, row.id)
        this.loading = false

        return
      }

      let treatmentIds = []
      if (row?.emr_treatments && row?.emr_treatments?.length) {
        treatmentIds = row?.emr_treatments.map(item => item?.id)
      } else {
        treatmentIds = [row?.id]
      }

      await this.ehcSyncCoreTreatment(treatmentIds).then(() => {
        this.loading = false
      })

      if (!row.id) {
        const params = {
          from: window.moment(row.date).startOf('day').toDate(),
          to: window.moment(row.date).endOf('day').toDate()
        }
        this.treatmentId = null
        await this.getERMTreatments(params)
        this.$router.push({
          query: {
            tab: this.$route.query.tab,
            treatment_date: window.moment(row.date).valueOf(),
            type: 'view'
          }
        })
        return
      }

      this.treatmentId = row.id
      if (row.pdf_url) {
        window.$('#modalViewUploadTreatment').modal(row.pdf_url ? 'show' : 'hide')
        await this.getERMTreatmentByIDV2()
      } else {
        const id = row.id
        this.form = row
        this.$router.push({
          query: {
            tab: this.$route.query.tab,
            id_treatment: id,
            type: 'view'
          }
        })
      }
    },
    async getERMTreatmentByIDV2 () {
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getERMTreatmentByIDV2(this.treatmentId)
        .then(r => {
          this.form = r.data
          if (!this.form.user) {
            this.form.user_id = this.form.user_name
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    ModalCreate (show) {
      window.$('#modalDieuTri').modal(show ? 'show' : 'hide')
    },
    handleCurrentPage (page) {
      let query = Object.assign({}, this.$route.query)
      query.page = page
      this.$router.replace({ query }).catch(_ => {})
      this.treatmentsPaging = {
        ...this.treatmentsPaging,
        current_page: page,
        data: this.treatments.slice((page - 1) * this.treatmentsPaging.per_page, page * this.treatmentsPaging.per_page)
      }
      // this.getERMTreatmentsByDate()
    },
    getDoctor (search = null) {
      if (this.$globalClinic) {
        if (this.$globalClinic?.org_id) {
          this.getOrgDoctor(search)
        } else {
          this.getClinicDoctor(search)
        }
      }
    },
    async getOrgDoctor (search = null) {
      const params = {
        limit: 50
      }
      if (search) {
        params.search = search
      }
      this.loadingDoctor = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getOrgDoctor(this.$globalClinic?.org_id, params)
        .then(r => {
          this.doctors = r.data
          const hasMe = this.doctors.find(d => d.user_id === this.$user.id)
          if (!hasMe && this.$user.doctor) this.doctors.push(this.$user.doctor)
          this.doctorPaging = r.meta
        })
        .finally(() => {
          this.loadingDoctor = false
        })
    },
    async getClinicDoctor (search) {
      const params = {
        limit: 50
      }
      if (search) {
        params.search = search
      }
      this.loadingDoctor = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getClinicDoctor(this.$globalClinic?.id, params)
        .then(r => {
          this.doctors = r.data.data
          this.doctors.map(d => {
            d.name = d.doctor?.name
            d.user_id = d.doctor?.user_id
          })
          const hasMe = this.doctors.find(d => d.doctor_id === this.$user?.doctor.id)
          if (!hasMe && this.$user.doctor) this.doctors.push(this.$user.doctor)
          this.doctorPaging = {
            count: r.data.count,
            current_page: r.data.current_page,
            last_page: r.data.last_page,
            per_page: r.data.per_page,
            total: r.data.total
          }
        })
        .finally(() => {
          this.loadingDoctor = false
        })
    },
    async getERMTreatments (params) {
      let id = this.$route.params.id
      if (!id) {
        return
      }
      const paramsData = {
        ...params,
        limit: 50,
        page: this.$route.query.page || 1,
        sort: 'date',
        sort_by: 'desc'
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getERMTreatments(id, paramsData)
        .then(r => {
          console.log(r)
          const listEmrTreatments = r.data?.data
          const indexPdf = listEmrTreatments.findIndex(x => x.pdf_url)
          if (indexPdf !== -1) {
            listEmrTreatments.splice(indexPdf, 1)
          }
          this.processDataWithApptDate(listEmrTreatments)
        })
        .finally(() => {
          this.loading = false
        })
    },
    processDataWithApptDate (data) {
      const sortArray = []
      data.forEach(x => {
        if (x.appt_date) {
          sortArray.push(x, {
            appt_date_only: x.appt_date,
            user_name: x.user_name
          })
        } else {
          // sortArray.push(x, {
          //   appt_date_only: null,
          //   user_name: null
          // })
          sortArray.push(x)
        }
      })
      console.log(sortArray)
      this.form = sortArray
    },
    async getERMTreatmentsByDate () {
      let id = this.$route.params.id
      if (!id) {
        return
      }
      const params = {
        limit: 365,
        page: this.$route.query.page || 1,
        sort: 'date',
        sort_by: 'desc'
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getERMTreatmentsByDate(id, params)
        .then(r => {
          const dataRaw = r.data
          this.treatments = dataRaw?.map(item => {
            const _emrTreatments = item?.emr_treatments?.sort((a, b) => new Date(b.date) - new Date(a.date))
            const _data = {
              ...item,
              emr_treatments: _emrTreatments
            }

            return _data
          })

          const meta = {
            ...this.pagination,
            total: dataRaw?.length,
            count: dataRaw?.length > this.pagination.per_page ? this.pagination.per_page : dataRaw?.length,
            last_page: Math.ceil(dataRaw?.length / this.pagination.per_page)
          }

          this.pagination = {
            ...meta,
            data: this.treatments.slice((meta.current_page - 1) * meta.per_page, meta.current_page * meta.per_page)
          }
          this.treatmentsPaging = {
            ...meta,
            data: this.treatments.slice((meta.current_page - 1) * meta.per_page, meta.current_page * meta.per_page)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    onShowModalUpload (show) {
      window.$('#modalEmrTreatUpload').modal(show ? 'show' : 'hide')
    },
    async postERMTreatment (event) {
      const formData = new FormData()
      for (const key in event) {
        if (event[key] && key === 'date') {
          formData.append(key, moment(event[key]).toISOString())
        } else if (event[key]) {
          formData.append(key, event[key])
        }
      }
      this.$refs.ModalEMRTreatUpload.is_loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .postERMTreatment(formData)
        .then(r => {
          if (r) this.getERMTreatmentsByDate()
          this.onShowModalUpload()
          this.$toast.open({
            message: 'Upload file thành công',
            type: 'success'
          })
          this.$refs.ModalEMRTreatUpload.is_loading = false
          this.closeModal()
        })
    },
    download (pdf) {
      window.open(pdf)
    },
    handleSyncData () {
      this.$message({
        type: 'success',
        message: this.$t('Sync OK')
      })
    },
    async handleSyncEHC () {
      const self = this
      try {
        // console.log(self.emrData, self.person)
        self.isSyncing = true
        const params = {
          org_id: self.$globalClinic?.org_id,
          ws_id: self.$globalClinic?.id,
          patient_id: Number(self.emrData?.person?.ehc_ref_id),
          medical_record_ref_id: Number(self.emrData?.ref_id),
          patient_record_id: Number(self.emrData?.ehc_patient_record_id)
        }
        await self.$rf
          .getRequest('EHCSyncRequest')
          .ehcSyncTreatment(params)
          .then(res => {
            if (!res?.data) return
            if (res?.data?.code === 401) {
              self.$message({
                type: 'error',
                message: self.$t(`Bạn không thể đồng bộ dữ liệu từ EHC`)
              })
              console.log(res)
              return
            }
            console.log(res?.data)
            self.getERMTreatmentsByDate()
            self.$message({
              type: 'success',
              message: self.$t(`Đồng bộ dữ liệu thành công`)
            })
            self.isSyncing = false
          })
      } catch (error) {
        console.log(error.response)
        self.$message({
          type: 'error',
          message: self.$t(`Đã có lỗi xảy ra, không thể đồng bộ dữ liệu`)
        })
      } finally {
        self.isSyncing = false
      }
    },
    async handleGetEnvelopeByDocumentID (item) {
      if (!item?.id) return

      try {
        this.loading = true
        const request = new SignatureRequest()
        const response = await request.getSignEnvelopeByDocumentID({
          doc_id: item.id,
          doc_type: ENVELOPE_DOCUMENT_TYPE.TREATMENT_FORM
        })

        return response.data?.document_url
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleSignSuccess () {
      this.$router.replace({ query: { tab: this.$route.query.tab } }).catch(_ => {})
    }
  }
}
</script>

<style scoped>
.animation {
  animation: rotating 2s linear infinite;
}
</style>
