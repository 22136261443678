<template>
  <div
    class="modal fade"
    id="modalDieuTri"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalDieuTriLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="d-flex justify-content-between p-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span>PHIẾU ĐIỀU TRỊ</span>
          </div>
          <button
            type="button"
            id="closeModal"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <div class="fs-14 fw-500">Chọn mẫu Phiếu điều trị</div>
              <el-select
                clearable
                filterable
                v-model="form.doc_template"
                class="w-full"
                placeholder="Chọn mẫu phiếu"
                @change="handleChangeDocTemplate"
                remote
                :remote-method="getDocsTemplateList"
                :loading="loading"
              >
                <el-option
                  v-for="item in docsTemplateList"
                  :key="item.id + 'md'"
                  :label="handleGetTemLabel(item)"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <label class="fw-700">Tên Phiếu</label>
              <input
                v-model="form.name"
                class="form--input txt-grey-900 h-10"
              />
            </div>

            <div class="col-md-3">
              <label class="fw-700">Ngày giờ điều trị</label>
              <input
                type="datetime-local"
                class="form--input"
                v-model="form.date"
              />
            </div>
          </div>

          <label class="fw-700 txt-grey-900 mb-2 mt-6">Chẩn đoán</label>
          <div class="d-flex justify-content-between">
            <div class="w-5/12 mr-3">
              <!-- <input v-model="form.diagnostic_code" class="form--input p-2.5" /> -->
              <el-select
                class="w-full"
                v-model="form.diagnostic_code"
                filterable
                remote
                :remote-method="searchDiseaseCodes"
                placeholder="Chọn mã"
                @change="setName"
              >
                <el-option
                  v-for="item in icds.data"
                  :key="item.id"
                  :label="item.code"
                  :value="item.code"
                ></el-option>
              </el-select>
            </div>
            <div class="w-100">
              <!-- <input v-model="form.diagnostic_name" class="form--input p-2.5" /> -->
              <el-select
                v-model="form.diagnostic_name"
                class="w-100"
                filterable
                allow-create
                remote
                :remote-method="searchDiseaseName"
                placeholder="Chọn tên"
                @change="setCode"
              >
                <el-option
                  v-for="item in icds.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="fw-700 mb-2">Diễn biến bệnh</div>
              <CkeditorNormal
                id="examination-pdt"
                @onEditorInput="
                  (t) => {
                    form.examination = t;
                  }
                "
                :prop_text="form.examination"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>

              <div class="fw-700 mb-2 mt-6">Khám bệnh</div>
              <CkeditorNormal
                id="disease_progression-pdt"
                @onEditorInput="
                  (t) => {
                    form.disease_progression = t;
                  }
                "
                :prop_text="form.disease_progression"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>

              <label class="fw-700 txt-grey-900 mb-2 mt-6">Xử trí</label>
              <CkeditorNormal
                id="solution-pdt"
                @onEditorInput="
                  (t) => {
                    form.solution = t;
                  }
                "
                :prop_text="form.solution"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>
              <!-- <label class="fw-700 txt-grey-900 mb-2 mt-6">Triệu chứng</label>
              <CkeditorNormal
                id="symptom-pdt"
                @onEditorInput="
                  (t) => {
                    form.symptom = t;
                  }
                "
                :prop_text="form.symptom"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>-->

              <!-- <label class="fw-700 txt-grey-900 mb-2 mt-6">Bệnh khác</label>
              <CkeditorNormal
                id="other_disease-pdt"
                @onEditorInput="
                  (t) => {
                    form.other_disease = t;
                  }
                "
                :prop_text="form.other_disease"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>-->
              <!-- <label class="fw-700 txt-grey-900 mb-2 mt-6">Kết luận</label>
              <CkeditorNormal
                id="conclusion-pdt"
                @onEditorInput="
                  (t) => {
                    form.conclusion = t;
                  }
                "
                :prop_text="form.conclusion"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>-->

              <label class="fw-700 txt-grey-900 mb-2 mt-6">Ngày hẹn</label>
              <input
                type="date"
                :min="disabledPast"
                class="form--input border-solid border-2 border-gray-400"
                v-model="form.appt_date"
              />
            </div>
            <div class="col-md-6">
              <div class="row">
                <span class="fw-700 txt-black col-md-8 mb-2"
                  >Chỉ định xét nghiệm</span
                >
                <div
                  class="col-md-4 d-flex justify-end items-center cursor-pointer"
                  @click="addItem(indications)"
                >
                  <svg
                    class="mr-1"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0767 5.4209H7.57666V0.920898C7.57666 0.721986 7.49764 0.531221 7.35699 0.390568C7.21634 0.249916 7.02557 0.170898 6.82666 0.170898C6.62775 0.170898 6.43698 0.249916 6.29633 0.390568C6.15568 0.531221 6.07666 0.721986 6.07666 0.920898V5.4209H1.57666C1.37775 5.4209 1.18698 5.49992 1.04633 5.64057C0.905678 5.78122 0.82666 5.97199 0.82666 6.1709C0.82666 6.36981 0.905678 6.56058 1.04633 6.70123C1.18698 6.84188 1.37775 6.9209 1.57666 6.9209H6.07666V11.4209C6.07666 11.6198 6.15568 11.8106 6.29633 11.9512C6.43698 12.0919 6.62775 12.1709 6.82666 12.1709C7.02557 12.1709 7.21634 12.0919 7.35699 11.9512C7.49764 11.8106 7.57666 11.6198 7.57666 11.4209V6.9209H12.0767C12.2756 6.9209 12.4663 6.84188 12.607 6.70123C12.7476 6.56058 12.8267 6.36981 12.8267 6.1709C12.8267 5.97199 12.7476 5.78122 12.607 5.64057C12.4663 5.49992 12.2756 5.4209 12.0767 5.4209Z"
                      fill="#20409B"
                    />
                  </svg>
                  Thêm mới
                </div>
                <div
                  class="relative"
                  v-for="(item, index) in indications"
                  :key="index"
                >
                  <div v-if="!item.deleted">
                    <div class="absolute top-2 right-3">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="deleteItem(indications, index)"
                        class="cursor-pointer"
                      >
                        <path
                          d="M6 18.4998L17.9998 6.5"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.9998 18.4998L6 6.5"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <textarea
                      :value="getFormattedName(item)"
                      @input="updateFormattedName(item, $event.target.value)"
                      class="form--input border-solid border-2 border-gray-400"
                      rows="2"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="row mt-6">
                <span class="fw-700 txt-black col-md-8 mb-2"
                  >Chẩn đoán hình ảnh</span
                >
                <div
                  class="col-md-4 d-flex justify-end items-center cursor-pointer"
                  @click="addItem(images)"
                >
                  <svg
                    class="mr-1"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0767 5.4209H7.57666V0.920898C7.57666 0.721986 7.49764 0.531221 7.35699 0.390568C7.21634 0.249916 7.02557 0.170898 6.82666 0.170898C6.62775 0.170898 6.43698 0.249916 6.29633 0.390568C6.15568 0.531221 6.07666 0.721986 6.07666 0.920898V5.4209H1.57666C1.37775 5.4209 1.18698 5.49992 1.04633 5.64057C0.905678 5.78122 0.82666 5.97199 0.82666 6.1709C0.82666 6.36981 0.905678 6.56058 1.04633 6.70123C1.18698 6.84188 1.37775 6.9209 1.57666 6.9209H6.07666V11.4209C6.07666 11.6198 6.15568 11.8106 6.29633 11.9512C6.43698 12.0919 6.62775 12.1709 6.82666 12.1709C7.02557 12.1709 7.21634 12.0919 7.35699 11.9512C7.49764 11.8106 7.57666 11.6198 7.57666 11.4209V6.9209H12.0767C12.2756 6.9209 12.4663 6.84188 12.607 6.70123C12.7476 6.56058 12.8267 6.36981 12.8267 6.1709C12.8267 5.97199 12.7476 5.78122 12.607 5.64057C12.4663 5.49992 12.2756 5.4209 12.0767 5.4209Z"
                      fill="#20409B"
                    />
                  </svg>
                  Thêm mới
                </div>
                <div
                  class="relative"
                  v-for="(item, index) in images"
                  :key="index"
                >
                  <div v-if="!item.deleted">
                    <div class="absolute top-2 right-3">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="deleteItem(images, index)"
                        class="cursor-pointer"
                      >
                        <path
                          d="M6 18.4998L17.9998 6.5"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.9998 18.4998L6 6.5"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <textarea
                      v-model="item.name"
                      class="form--input border-solid border-2 border-gray-400"
                      rows="2"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row mt-6">
                <span class="fw-700 txt-black col-md-8 mb-2">Thuốc</span>
                <div
                  class="col-md-4 d-flex justify-end items-center cursor-pointer"
                  @click="addItem(medicines1, 1)"
                >
                  <svg
                    class="mr-1"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0767 5.4209H7.57666V0.920898C7.57666 0.721986 7.49764 0.531221 7.35699 0.390568C7.21634 0.249916 7.02557 0.170898 6.82666 0.170898C6.62775 0.170898 6.43698 0.249916 6.29633 0.390568C6.15568 0.531221 6.07666 0.721986 6.07666 0.920898V5.4209H1.57666C1.37775 5.4209 1.18698 5.49992 1.04633 5.64057C0.905678 5.78122 0.82666 5.97199 0.82666 6.1709C0.82666 6.36981 0.905678 6.56058 1.04633 6.70123C1.18698 6.84188 1.37775 6.9209 1.57666 6.9209H6.07666V11.4209C6.07666 11.6198 6.15568 11.8106 6.29633 11.9512C6.43698 12.0919 6.62775 12.1709 6.82666 12.1709C7.02557 12.1709 7.21634 12.0919 7.35699 11.9512C7.49764 11.8106 7.57666 11.6198 7.57666 11.4209V6.9209H12.0767C12.2756 6.9209 12.4663 6.84188 12.607 6.70123C12.7476 6.56058 12.8267 6.36981 12.8267 6.1709C12.8267 5.97199 12.7476 5.78122 12.607 5.64057C12.4663 5.49992 12.2756 5.4209 12.0767 5.4209Z"
                      fill="#20409B"
                    />
                  </svg>
                  Thêm mới
                </div>
                <div
                  class="relative"
                  v-for="(item, index) in medicines1"
                  :key="index"
                >
                  <div v-if="!item.deleted">
                    <div class="absolute top-2 right-3">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="deleteItem(medicines1, index)"
                        class="cursor-pointer"
                      >
                        <path
                          d="M6 18.4998L17.9998 6.5"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.9998 18.4998L6 6.5"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <textarea
                      v-model="item.name"
                      class="form--input border-solid border-2 border-gray-400"
                      rows="2"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row mt-6">
                <span class="fw-700 txt-black col-md-8 mb-2">Thuốc tự túc</span>
                <div
                  class="col-md-4 d-flex justify-end items-center cursor-pointer"
                  @click="addItem(medicines2, 2)"
                >
                  <svg
                    class="mr-1"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0767 5.4209H7.57666V0.920898C7.57666 0.721986 7.49764 0.531221 7.35699 0.390568C7.21634 0.249916 7.02557 0.170898 6.82666 0.170898C6.62775 0.170898 6.43698 0.249916 6.29633 0.390568C6.15568 0.531221 6.07666 0.721986 6.07666 0.920898V5.4209H1.57666C1.37775 5.4209 1.18698 5.49992 1.04633 5.64057C0.905678 5.78122 0.82666 5.97199 0.82666 6.1709C0.82666 6.36981 0.905678 6.56058 1.04633 6.70123C1.18698 6.84188 1.37775 6.9209 1.57666 6.9209H6.07666V11.4209C6.07666 11.6198 6.15568 11.8106 6.29633 11.9512C6.43698 12.0919 6.62775 12.1709 6.82666 12.1709C7.02557 12.1709 7.21634 12.0919 7.35699 11.9512C7.49764 11.8106 7.57666 11.6198 7.57666 11.4209V6.9209H12.0767C12.2756 6.9209 12.4663 6.84188 12.607 6.70123C12.7476 6.56058 12.8267 6.36981 12.8267 6.1709C12.8267 5.97199 12.7476 5.78122 12.607 5.64057C12.4663 5.49992 12.2756 5.4209 12.0767 5.4209Z"
                      fill="#20409B"
                    />
                  </svg>
                  Thêm mới
                </div>
                <div
                  class="relative"
                  v-for="(item, index) in medicines2"
                  :key="index"
                >
                  <div v-if="!item.deleted">
                    <div class="absolute top-2 right-3">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="deleteItem(medicines2, index)"
                        class="cursor-pointer"
                      >
                        <path
                          d="M6 18.4998L17.9998 6.5"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.9998 18.4998L6 6.5"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <textarea
                      v-model="item.name"
                      class="form--input border-solid border-2 border-gray-400"
                      rows="2"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="row mt-6">
                <span class="fw-700 txt-black col-md-8 mb-2">Vật tư</span>
                <div
                  class="col-md-4 d-flex justify-end items-center cursor-pointer"
                  @click="addItem(materials)"
                >
                  <svg
                    class="mr-1"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0767 5.4209H7.57666V0.920898C7.57666 0.721986 7.49764 0.531221 7.35699 0.390568C7.21634 0.249916 7.02557 0.170898 6.82666 0.170898C6.62775 0.170898 6.43698 0.249916 6.29633 0.390568C6.15568 0.531221 6.07666 0.721986 6.07666 0.920898V5.4209H1.57666C1.37775 5.4209 1.18698 5.49992 1.04633 5.64057C0.905678 5.78122 0.82666 5.97199 0.82666 6.1709C0.82666 6.36981 0.905678 6.56058 1.04633 6.70123C1.18698 6.84188 1.37775 6.9209 1.57666 6.9209H6.07666V11.4209C6.07666 11.6198 6.15568 11.8106 6.29633 11.9512C6.43698 12.0919 6.62775 12.1709 6.82666 12.1709C7.02557 12.1709 7.21634 12.0919 7.35699 11.9512C7.49764 11.8106 7.57666 11.6198 7.57666 11.4209V6.9209H12.0767C12.2756 6.9209 12.4663 6.84188 12.607 6.70123C12.7476 6.56058 12.8267 6.36981 12.8267 6.1709C12.8267 5.97199 12.7476 5.78122 12.607 5.64057C12.4663 5.49992 12.2756 5.4209 12.0767 5.4209Z"
                      fill="#20409B"
                    />
                  </svg>
                  Thêm mới
                </div>
                <div
                  class="relative"
                  v-for="(item, index) in materials"
                  :key="index"
                >
                  <div v-if="!item.deleted">
                    <div class="absolute top-2 right-3">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="deleteItem(materials, index)"
                        class="cursor-pointer"
                      >
                        <path
                          d="M6 18.4998L17.9998 6.5"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.9998 18.4998L6 6.5"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <textarea
                      v-model="item.name"
                      class="form--input border-solid border-2 border-gray-400"
                      rows="2"
                    ></textarea>
                  </div>
                </div>
              </div>
              <label class="fw-700 txt-grey-900 mb-2 mt-6">Chế độ ăn</label>
              <CkeditorNormal
                id="diet_order-pdt"
                @onEditorInput="
                  (t) => {
                    form.diet_order = t;
                  }
                "
                :prop_text="form.diet_order"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>
              <label class="fw-700 txt-grey-900 mb-2 mt-6"
                >Chế độ chăm sóc</label
              >
              <CkeditorNormal
                id="care_order-pdt"
                @onEditorInput="
                  (t) => {
                    form.care_order = t;
                  }
                "
                :prop_text="form.care_order"
                :class_style="'bg-white p-0 txt-black'"
              ></CkeditorNormal>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="onSubmit" type="button" class="btn bg-pri text-white">
            Lưu phiếu
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
import html2pdf from 'html2pdf.js'
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex'
import appUtils from '../../../utils/appUtils'
import { MENU_EMR_TEMPLATE } from '../../../utils/constants'
import {mixinHasFeatures} from '@/utils/mixinHasFeatures'

export default {
  props: ['type'],
  name: 'ModalTreatment',
  mixins: [mixinHasFeatures],
  components: { CkeditorNormal },
  data () {
    return {
      medicines1: [
        {
          code: '',
          name: '',
          type: 1,
          remark: ''
        }
      ],
      medicines2: [
        {
          code: '',
          name: '',
          type: 2,
          remark: ''
        }
      ],
      materials: [
        {
          code: '',
          name: '',
          remark: ''
        }
      ],
      images: [
        {
          code: '',
          name: '',
          remark: ''
        }
      ],
      indications: [
        {
          code: '',
          name: '',
          remark: ''
        }
      ],
      dataTable: [],
      form: {
        name: 'Phiếu điều trị ' + window.moment().format('DD/MM/YYYY'),
        date: moment().format(`YYYY-MM-DDTHH:mm:ssZ`),
        appt_date: '',
        examination: '',
        symptom: '',
        other_disease: '',
        solution: '',
        diagnostic_code: '',
        diagnostic_name: '',
        diet_order: '',
        care_order: '',
        disease_progression: '',
        emr_treatment_medicines: [],
        emr_treatment_materials: [],
        emr_treatment_images: [],
        emr_treatment_indications: [],
        format: 1,
        doc_template: null
      },
      loading: false,
      docsTemplateList: [],
      icds: []
    }
  },
  computed: {
    ...mapState({
      person: (state) => state.person,
      medicalRecordMain: (state) => state.medicalRecordMain,
      emrData: (state) => state.emrData
    }),
    disabledPast () {
      const currentDate = moment().format('YYYY-MM-DD')
      return currentDate
    }
  },
  mounted () {
    this.getDocsTemplateList()
    this.getIcd10Name()

    if (this.type === 'update') {
      this.refresh()
      this.getERMTreatmentByIDV2()
    }
    if (this.type === 'create') {
      let self = this

      self.$nextTick(function () {
        window.$('#modalDieuTri').on('shown.bs.modal', function (e) {
          self.form.diagnostic_code =
            self.medicalRecordMain?.treat_dept_main_code || ''
          self.form.diagnostic_name =
            self.medicalRecordMain?.treat_dept_main_name || ''
          self.form.date = moment().format(`YYYY-MM-DDTHH:mm`)
          self.form.emr_treatment_medicines = []
          self.form.emr_treatment_materials = []
          self.form.emr_treatment_images = []
          self.form.emr_treatment_indications = []
        })

        window.$('#modalDieuTri').on('hidden.bs.modal', function (e) {
          self.form.diagnostic_code = ''
          self.form.diagnostic_name = ''
          self.refresh()
        })
      })
    }
  },
  methods: {
    getFormattedName (item) {
      return this.hasVimesSync
        ? (item.vimes_original_name || item.name)
        : (item.name || item.Name)
    },
    updateFormattedName (item, value) {
      if (this.hasVimesSync) {
        if (item.vimes_original_name) {
          item.vimes_original_name = value
        } else {
          item.name = value
        }
      } else {
        if (item.name) {
          item.name = value
        } else {
          item.Name = value
        }
      }
    },
    async onSubmit () {
      this.form.medical_record_id = this.$route.params.id

      if (this.type === 'create') {
        this.form.user_id = this.$user?.id
        this.form.user_name = this.$user?.name
        this.form.treatment_workspace = this.$globalClinic.name
        await this.postERMTreatment()
        this.refresh()
        window.$('button#closeModal').trigger('click')
      } else {
        this.form.user_id = this.$user?.id
        this.form.user_name = this.$user?.name
        await this.updateERMTreatment()
        // await this.$emit('refreshERM')
        // await this.getERMTreatmentByIDV2()
        window.$('button#closeModal').trigger('click')
      }
      this.$emit('onSuccess')
    },
    refresh () {
      this.form = {
        name: 'Phiếu điều trị ' + window.moment().format('DD/MM/YYYY'),
        date: moment().format(`YYYY-MM-DDTHH:mm`),
        appt_date: '',
        examination: '',
        symptom: '',
        other_disease: '',
        solution: '',
        diagnostic_code: '',
        diagnostic_name: '',
        disease_progression: '',
        diet_order: '',
        care_order: '',
        format: 1,
        emr_treatment_medicines: [],
        emr_treatment_materials: [],
        emr_treatment_images: [],
        emr_treatment_indications: []
      }
      this.medicines1 = [
        {
          code: '',
          name: '',
          type: 1,
          remark: ''
        }
      ]
      this.medicines2 = [
        {
          code: '',
          name: '',
          type: 2,
          remark: ''
        }
      ]
      this.materials = [
        {
          code: '',
          name: '',
          remark: ''
        }
      ]
      this.images = [
        {
          code: '',
          name: '',
          remark: ''
        }
      ]
      this.indications = [
        {
          code: '',
          name: '',
          remark: ''
        }
      ]
    },
    getGender () {
      return appUtils.getGender(this.person.gender)
    },
    formatDate (date) {
      if (!date) return ''
      return window.moment(date).format('HH:mm DD/MM/YYYY')
    },
    getAge () {
      return (
        this.person.birthday &&
        (window.moment().diff(this.person.birthday, 'years', false)
          ? window.moment().diff(this.person.birthday, 'years', false)
          : '')
      )
    },
    getTreatDeptMain () {
      let result = ''
      if (this.medicalRecordMain && this.medicalRecordMain.disease_main_code) {
        result += this.medicalRecordMain.disease_main_code
      }
      if (this.medicalRecordMain && this.medicalRecordMain.disease_main_name) {
        result = result + ' ' + this.medicalRecordMain.disease_main_name
      }
      return result
    },
    async getERMTreatmentByIDV2 () {
      let id = this.$route.query.id_treatment
      if (!id) {
        return
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getERMTreatmentByIDV2(id)
        .then((r) => {
          this.form = {
            name: r.data.name,
            appt_date: r.data.appt_date
              ? appUtils.formatInputDate(r.data.appt_date)
              : '',
            date: r.data.date ? appUtils.formatInputDateTime(r.data.date) : '',
            disease_progression: r.data.disease_progression || '',
            examination: r.data.examination || '',
            symptom: r.data.symptom || '',
            other_disease: r.data.other_disease || '',
            solution: r.data.solution || '',
            conclusion: r.data.conclusion || '',
            diagnostic_code: r.data.diagnostic_code || '',
            diagnostic_name: r.data.diagnostic_name || '',
            diet_order: r.data.diet_order || '',
            care_order: r.data.care_order || ''
          }
          this.mapData(r.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async postERMTreatment () {
      this.loading = true
      this.deleteValueNull(this.medicines1)
      this.deleteValueNull(this.medicines2)
      this.deleteValueNull(this.materials)
      this.deleteValueNull(this.images)
      this.deleteValueNull(this.indications)

      const params = {
        ...this.form,
        date: moment(this.form.date).utc().format('YYYY-MM-DDTHH:mm'),
        medical_record_id: this.$route.params.id,
        emr_treatment_medicines: [...this.medicines1, ...this.medicines2],
        emr_treatment_materials: [...this.materials],
        emr_treatment_images: [...this.images],
        emr_treatment_indications: [...this.indications]
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postERMTreatment(params)
        .then((r) => {
          const dataId = r.data
          this.goToViewDetail(dataId)
          this.$toast.open({
            message: 'Tải lên thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
      this.refresh()
    },
    async updateERMTreatment () {
      let id = this.$route.query.id_treatment
      this.loading = true
      this.deleteValueNull(this.medicines1)
      this.deleteValueNull(this.medicines2)
      this.deleteValueNull(this.materials)
      this.deleteValueNull(this.images)
      this.deleteValueNull(this.indications)

      const params = {
        ...this.form,
        date: moment(this.form.date).utc().format('YYYY-MM-DDTHH:mm'),
        medical_record_id: this.$route.params.id,
        emr_treatment_medicines: [...this.medicines1, ...this.medicines2],
        emr_treatment_materials: [...this.materials],
        emr_treatment_images: [...this.images],
        emr_treatment_indications: [...this.indications]
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .updateERMTreatment(id, params)
        .then((r) => {
          // this.refresh()
          // this.goToViewDetail(r.data.id)
          this.$emit('refreshERM', r.data.id)
          this.$toast.open({
            message: 'Chỉnh sửa thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    goToViewDetail (row) {
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          id_treatment: row.id,
          type: 'view'
        }
      })
      this.$emit('refreshERM', row.id)
    },
    mapData (data) {
      if (data.materials) this.materials = data.materials
      if (data.images) {
        this.images = data.images
      }
      if (data.indications) {
        this.indications = data.indications
      }
      if (data.medicines) {
        this.medicines1 = data.medicines.filter((item) => {
          return item.type === 1
        })
        this.medicines2 = data.medicines.filter((item) => {
          return item.type === 2
        })
      }
    },
    deleteValueNull (array) {
      _.remove(array, function (item) {
        return !item.name
      })
    },
    addItem (item, type) {
      const temp = {
        code: '',
        name: '',
        remark: ''
      }
      if (type) temp['type'] = type
      item.push(temp)
    },
    deleteItem (item, index) {
      if (item[index].id) {
        this.$set(item[index], 'deleted', true)
      } else item.splice(index, 1)
    },
    goToView (type) {
      this.$router.replace({
        query: {
          tab: this.$route.query.tab,
          id_treatment: this.$route.query.id_treatment,
          type
        }
      })
    },
    backList () {
      this.$router.replace({ query: { tab: this.$route.query.tab } })
    },
    exportPdf () {
      this.loading = true
      setTimeout(async () => {
        let options = {
          margin: [7, 11, 7, 7],
          filename: `Phieu_Dieu_Tri_${this.form.name}_Ngay_${this.form.date}.pdf`,
          html2canvas: {
            dpi: 1000,
            scale: 6,
            image: { type: 'png', quality: 1 },
            letterRendering: true,
            allowTaint: true,
            logging: true,
            useCORS: true
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }
        await html2pdf().from(this.$refs.viewDetail).set(options).save()
        this.loading = false
      }, 10)
    },
    openFile (url) {
      window.open(url)
    },
    async getDocsTemplateList (search) {
      try {
        this.loading = true
        const params = {
          workspace_id: this.$globalClinic.id,
          limit: 1000,
          sort_by: 'desc',
          type: Number(MENU_EMR_TEMPLATE[3].value)
        }
        if (search) {
          params.keyword = search
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getDocsTemplateList(params)
          .then((r) => {
            const responseData = r.data?.data?.data || []
            this.docsTemplateList = responseData.filter(
              (item) =>
                item?.author_id === this.$user?.id ||
                item?.emr_temp_name?.is_public === 1
            )
          })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleChangeDocTemplate (selectedDoc) {
      const docTemplateInfo =
        this.docsTemplateList.find((item) => item.id === selectedDoc) || {}
      const contentObj = docTemplateInfo.content
        ? JSON.parse(docTemplateInfo.content)
        : {}

      this.form = {
        ...this.form,
        name: docTemplateInfo.name,
        disease_progression: contentObj.disease_progression?.value,
        solution: contentObj.solution?.value,
        diet_order: contentObj.diet_order?.value,
        care_order: contentObj.care_order?.value
      }
    },
    handleGetTemLabel (data) {
      if (data?.emr_temp_name?.name) {
        return `${data.name} - ${data.emr_temp_name.name}`
      }

      return data.name
    },
    async getIcd10 (value) {
      const p = {
        limit: 50,
        code: value || this.form.diagnostic_code
      }
      const res = await this.$rf.getRequest('AuthRequest').getIcd10(p)
      this.icds = res?.data
    },
    async getIcd10Name (value) {
      const p = {
        limit: 50,
        name: value || this.form.diagnostic_name
      }
      const res = await this.$rf.getRequest('AuthRequest').getIcd10(p)
      this.icds = res?.data
    },
    searchDiseaseCodes (value) {
      return this.getIcd10(value)
    },
    searchDiseaseName (value) {
      return this.getIcd10Name(value)
    },
    setName (value) {
      const item = _.find(this.icds.data, ['code', value])
      this.form.diagnostic_name = item?.name
    },
    setCode (value) {
      const item = _.find(this.icds.data, ['name', value])
      this.form.diagnostic_code = item?.code
    }
  }
}
</script>
